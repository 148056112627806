import PropTypes from 'prop-types';
import { Link as InLink } from '@inertiajs/react';

import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import React from 'react';

const LinkVariants = cva('transition-all font-normal focus-visible:outline focus-visible:outline-4 focus-visible:outline-notificationsWarning', {
	variants: {
		variant: {
			dark: 'w-max text-corporateBlueBright hover:text-corporateLightBlueHover relative before:bg-corporateBlueBrighOpa20 before:absolute before:h-px before:w-full before:bottom-[-2px] before:left-0 hover:before:opacity-0 before:transition-all before:duration-300 after:bg-corporateBlueBright after:absolute after:h-px after:w-0 after:bottom-[-2px] after:left-0 hover:after:w-full after:transition-all after:duration-300 font-bold',
			light:
				'text-white relative after:bg-white after:absolute after:h-px after:w-0 after:bottom-[-2px] after:left-0 hover:after:w-full after:transition-all after:duration-300 font-bold',
			disabled: 'text-eis-link-disable-grey border-b border-eis-link-disable-grey-opa-20 font-bold',
		},
		size: {
			default: 'text-base',
			small: 'text-14xs leading-eis-1.29',
		},
	},
	defaultVariants: {
		variant: 'dark',
		size: 'default',
		isExternal: false,
	},
});

function Link({ variant, size, href, isExternal, className, children, disabled, isGeneralLink, ...props }) {
	if (disabled) {
		variant = 'disabled';
	}

	const Component = isGeneralLink ? 'a' : InLink;

	return (
		<Component
			href={!disabled ? href : undefined}
			target={isExternal ? '_blank' : '_self'}
			className={cn(LinkVariants({ variant, size, className }))}
			{...props}
		>
			{children}
		</Component>
	);
}
export default Link;

Link.propTypes = {
	variant: PropTypes.oneOf(['dark', 'light']),
	size: PropTypes.oneOf(['default', 'small']).isRequired,
	href: PropTypes.string.isRequired,
	isExternal: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]).isRequired,
	disabled: PropTypes.bool,
};

Link.defaultProps = {
	variant: null,
	size: 'default',
	href: null,
	isExternal: false,
	className: null,
	children: null,
	disabled: false,
	isGeneralLink: false,
};
